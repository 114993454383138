.hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._1IBkKVQX5XKviswK1OuMeC {
  background: #030b0d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 7.5rem 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative; }
  @media (max-width: 991px) {
    ._1IBkKVQX5XKviswK1OuMeC {
      padding: 4rem 2rem; } }
  ._1IBkKVQX5XKviswK1OuMeC._2frTGpvtmLjNYvVejKiHUG {
    background: #f5f7f8;
    width: 100%; }
    ._1IBkKVQX5XKviswK1OuMeC._2frTGpvtmLjNYvVejKiHUG ._1UOTorYBL03OC7nZNYBTUZ {
      -webkit-box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78);
              box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78); }
  @media (max-width: 991px) {
    ._1IBkKVQX5XKviswK1OuMeC:before, ._1IBkKVQX5XKviswK1OuMeC:after {
      content: none; } }

._1UOTorYBL03OC7nZNYBTUZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fcfcfc;
  border-radius: 30px;
  max-width: 940px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
  padding: 3rem 7rem;
  min-height: 565px; }
  ._1UOTorYBL03OC7nZNYBTUZ._2YPpA-heySaXc1mv_1OVnD {
    height: auto; }
  @media all and (min-width: 768px) {
    ._1UOTorYBL03OC7nZNYBTUZ {
      min-height: 555px; } }

@-webkit-keyframes _35bptqskiwo4r-xB6HMWqm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes _35bptqskiwo4r-xB6HMWqm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }
  ._1UOTorYBL03OC7nZNYBTUZ._1OoUkZxJDBchwjrdVrOQuF {
    -webkit-animation: _35bptqskiwo4r-xB6HMWqm 1s;
            animation: _35bptqskiwo4r-xB6HMWqm 1s; }
  @media (max-width: 991px) {
    ._1UOTorYBL03OC7nZNYBTUZ {
      padding: 3rem 0; } }

._1sVE2MKJeRyizs-BNFw2t7 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    max-width: 100%; }
    ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR.ZeVvzW0s13gx11AlmJlIn {
      opacity: 1;
      position: relative;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR._16XCwom--GfRQvTRrZOVbF, ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR._1n3tx_gUb4OyRuDh2nnaZS {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
    ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR._1X5X8EkkmLu061NbWlV5pJ, ._1sVE2MKJeRyizs-BNFw2t7 ._2vcpYwID7TLfGoKM3JK5OR._3QPrJ-5hIyv6ZpCrFHQHUj {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }

.hAwLuEeMEaP7nmDSgvWsn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    .hAwLuEeMEaP7nmDSgvWsn {
      padding: 1rem; } }
  .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin-bottom: 3rem; }
    .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE {
      font-size: 2rem;
      cursor: pointer;
      font-weight: bold; }
      .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE:hover {
        color: #adadad; }
      .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE:active,
      .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE :focus {
        color: #8d8d8d; }
      .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE._16XCwom--GfRQvTRrZOVbF {
        margin-right: 3rem; }
        @media (max-width: 991px) {
          .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE._16XCwom--GfRQvTRrZOVbF {
            margin-right: 1rem; } }
        .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE._16XCwom--GfRQvTRrZOVbF::before {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-transform: rotate(0.5turn);
                  transform: rotate(0.5turn); }
      .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE._1X5X8EkkmLu061NbWlV5pJ {
        margin-left: 3rem; }
        @media (max-width: 991px) {
          .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE._1X5X8EkkmLu061NbWlV5pJ {
            margin-left: 1rem; } }
      @media (max-width: 991px) {
        .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._1U8n5mygmINnLj7udf-lbE {
          font-size: 1.3rem; } }
    .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._3BcedN322ZkPt0qHjHodm_ {
      max-width: 110px;
      max-height: 110px;
      border-radius: 50%;
      display: block;
      overflow: hidden; }
      @media (max-width: 991px) {
        .hAwLuEeMEaP7nmDSgvWsn ._36uAuH3vkw04-NYBIORRYV ._3BcedN322ZkPt0qHjHodm_ {
          max-width: 90px;
          max-height: 90px; } }
  .hAwLuEeMEaP7nmDSgvWsn .l-2JTSrs8wnvver6YD8S2 {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
    text-align: center; }
    @media all and (min-width: 1344px) {
      .hAwLuEeMEaP7nmDSgvWsn .l-2JTSrs8wnvver6YD8S2 {
        font-size: 1.25rem;
        line-height: 1.8; } }
    .hAwLuEeMEaP7nmDSgvWsn .l-2JTSrs8wnvver6YD8S2._2D2sW4I4VuSsQ3-F3R2gLF {
      display: block;
      max-height: unset; }
  .hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew {
    color: #030b0d;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center; }
    @media all and (min-width: 1344px) {
      .hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew {
        font-size: 1.4rem; } }
    .hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew:hover {
      color: #00b4d8; }
    .hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew:active,
    .hAwLuEeMEaP7nmDSgvWsn ._3QVFZNoRTSYpJiJawr3cew :focus {
      color: #2b2f3a; }
  .hAwLuEeMEaP7nmDSgvWsn ._2n2ozY1KJqyWel6qrzdPSk {
    display: block; }
  .hAwLuEeMEaP7nmDSgvWsn ._16kbXpp0H8szzX6eavM3at {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    color: #00b4d8;
    cursor: pointer;
    text-decoration: underline; }
    .hAwLuEeMEaP7nmDSgvWsn ._16kbXpp0H8szzX6eavM3at:hover {
      color: #009bb9;
      font-weight: 600; }

._3RXGG69I8xF65-JHhxvKVH {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 768px) {
    ._3RXGG69I8xF65-JHhxvKVH {
      -ms-flex-preferred-size: 60%;
          flex-basis: 60%; } }
  ._3RXGG69I8xF65-JHhxvKVH ._1UOTorYBL03OC7nZNYBTUZ {
    padding: 1rem 1.5rem;
    min-height: 565px; }
    ._3RXGG69I8xF65-JHhxvKVH ._1UOTorYBL03OC7nZNYBTUZ._2YPpA-heySaXc1mv_1OVnD {
      height: auto; }
    @media all and (min-width: 768px) {
      ._3RXGG69I8xF65-JHhxvKVH ._1UOTorYBL03OC7nZNYBTUZ {
        min-height: 520px; } }
    @media all and (min-width: 1280px) {
      ._3RXGG69I8xF65-JHhxvKVH ._1UOTorYBL03OC7nZNYBTUZ {
        padding: 2rem 2.5rem; } }
  ._3RXGG69I8xF65-JHhxvKVH ._36uAuH3vkw04-NYBIORRYV {
    margin-bottom: 1rem; }
  ._3RXGG69I8xF65-JHhxvKVH .l-2JTSrs8wnvver6YD8S2 {
    position: relative;
    overflow: hidden;
    max-height: 430px; }
  ._3RXGG69I8xF65-JHhxvKVH ._1U8n5mygmINnLj7udf-lbE {
    color: #030b0d;
    background: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    margin: 0.5rem;
    line-height: 1.6; }
    ._3RXGG69I8xF65-JHhxvKVH ._1U8n5mygmINnLj7udf-lbE:hover {
      color: #adadad; }
    @media (max-width: 991px) {
      ._3RXGG69I8xF65-JHhxvKVH ._1U8n5mygmINnLj7udf-lbE {
        font-size: 1rem;
        width: 25px;
        height: 25px;
        margin: 0.2rem; } }
